import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  Col,
  Button,
  Row,
  Container,
  Card,
  Form,
  Table,
} from "react-bootstrap";

import Accordion from "react-bootstrap/Accordion";
import { addPlayer } from "../resources/db";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "../firebase";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3";
import * as formik from "formik";
import * as yup from "yup";
import YupPassword from "yup-password";
import { useSearchParams } from "react-router-dom";
import { useFormikContext } from "formik";
YupPassword(yup);

export default function RegisterForm({ setPlayerData }) {
  const [error, setError] = useState(null);
  const [token, setToken] = useState("");
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const { Formik } = formik;
  const [searchParams] = useSearchParams();
  const origen = searchParams.get("origen");

  const schema = yup.object().shape({
    name: yup.string().required("El nombre es requerido"),
    paternalName: yup.string().required("El primer apellido es requerido"),
    // maternalName: yup.string().required("El segundo apellido es requerido"),
    birthDate: yup.string().required("La fecha de nacimiento es requerida"),
    // club: yup.string().required("El club es requerido"),
    city: yup.string().required("La ciudad de procedencia es requerida"),
    cellphone: yup.string().required("El telefono es requerido"),
    curp: yup
      .mixed()
      .required("La CURP es requerida")
      .test("fileFormat", "Archivo invalido", (value) => {
        console.log(value);
        if (value) {
          const supportedFormats = ["pdf", "png", "jpg", "jpeg"];
          return supportedFormats.includes(value.name.split(".").pop());
        }
        return true;
      })
      .test(
        "fileSize",
        "El archivo debe ser de un tamaño menor o igual a 5MB",
        (value) => {
          if (value) {
            return value.size <= 5 * 1024 * 1024;
          }
          return true;
        }
      ),
    voucher:
      origen != "suchil"
        ? yup
            .mixed()
            .required("El comprobante de pago es requerido")
            .test("fileFormat", "Archivo invalido", (value) => {
              console.log(value);
              if (value) {
                const supportedFormats = ["pdf", "png", "jpg", "jpeg"];
                return supportedFormats.includes(value.name.split(".").pop());
              }
              return true;
            })
            .test(
              "fileSize",
              "El archivo debe ser de un tamaño menor o igual a 1MB",
              (value) => {
                if (value) {
                  return value.size <= 1024 * 1024;
                }
                return true;
              }
            )
        : yup.mixed(),
  });

  const onVerify = useCallback((token) => {
    setToken(token);
  });
  return (
    <Formik
      validationSchema={schema}
      onSubmit={async (values) => {
        try {
          const curpStorageRef = ref(storage, "images/" + values.curp.name);
          const voucherStorageRef = ref(
            storage,
            "images/" + values.voucher.name
          );

          const curpSnapshot = await uploadBytes(curpStorageRef, values.curp);
          const urlCurp = await getDownloadURL(curpSnapshot.ref);
          console.log("CURP URL", urlCurp);
          const voucherSnapshot = await uploadBytes(
            voucherStorageRef,
            values.voucher
          );
          const urlVoucher = await getDownloadURL(voucherSnapshot.ref);
          console.log("CURP URL", urlVoucher);

          const data =
            origen != "suchil"
              ? {
                  ...values,
                  curp: urlCurp,
                  voucher: urlVoucher,
                }
              : {
                  ...values,
                  curp: urlCurp,
                  // voucher: "urlVoucher",
                };
          console.log("DATA", data);
          await addPlayer(data);
          setRefreshReCaptcha((r) => !r);
          setPlayerData(values);
        } catch (e) {
          setError(e.message);
          console.log(e);
        }
      }}
      initialValues={{
        name: "",
        paternalName: "",
        maternalName: "",
        birthDate: "",
        club: "",
        city: origen == "suchil" ? "Suchil" : "",
        cellphone: "",
        curp: "",
        voucher: "",
      }}
      enableReinitialize={true}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        values,
        touched,
        errors,
        dirty,
        isValid,
      }) => (
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xs={12}>
              <div className="border-3 border-primary border"></div>
              <Card className="shadow">
                <Card.Body>
                  <div className="mb-3 mt-4">
                    <center>
                      <h2 className="fw-bold mb-2">
                        1er Torneo de Ajedrez Feria Patronal Súchil 2024
                      </h2>
                    </center>
                    {error && <p>{error}</p>}
                    <Form className="mb-3" noValidate onSubmit={handleSubmit}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="text-center">
                          Nombre(s)
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Introduce tu nombre"
                          name="name"
                          value={values?.name}
                          onChange={handleChange}
                          isInvalid={!!errors.name}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.name}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicPassword"
                      >
                        <Form.Label>Primer apellido</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Introduce tu primer apellido"
                          name="paternalName"
                          value={values?.paternalName}
                          onChange={handleChange}
                          isInvalid={!!errors.paternalName}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.paternalName}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicPassword"
                      >
                        <Form.Label>Segundo apellido (Opcional)</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Introduce tu segundo apellido"
                          name="maternalName"
                          value={values?.maternalName}
                          onChange={handleChange}
                          isInvalid={!!errors.maternalName}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.maternalName}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicPassword"
                      >
                        <Form.Label>Fecha de nacimiento</Form.Label>
                        <Form.Control
                          type="date"
                          placeholder="Introduce tu fecha de nacimiento"
                          name="birthDate"
                          value={values?.birthDate}
                          onChange={handleChange}
                          isInvalid={!!errors.birthDate}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.birthDate}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicPassword"
                      >
                        <Form.Label>Club (Opcional)</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Introduce tu club"
                          name="club"
                          value={values?.club}
                          onChange={handleChange}
                          isInvalid={!!errors.club}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.club}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicPassword"
                      >
                        <Form.Label>Ciudad de procedencia</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Introduce tu ciudad de procedencia"
                          name="city"
                          value={values.city}
                          onChange={handleChange}
                          isInvalid={!!errors.city}
                          disabled={values.city == "Suchil"}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.city}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicPassword"
                      >
                        <Form.Label>Telefono de contacto</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Introduce tu teleono de contacto"
                          name="cellphone"
                          value={values?.cellphone}
                          onChange={handleChange}
                          isInvalid={!!errors.cellphone}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.cellphone}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group
                        className="mb-3"
                        controlId="formBasicPassword"
                      >
                        <Form.Label>CURP</Form.Label>
                        <Form.Control
                          type="file"
                          placeholder="Introduce tu curp"
                          name="curp"
                          onChange={(e) => {
                            setFieldValue("curp", e.target.files[0]);
                          }}
                          accept=".pdf,.png,.jpeg,.jpg"
                          isInvalid={!!errors.curp}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.curp}
                        </Form.Control.Feedback>
                      </Form.Group>
                      {values.city !== "Suchil" && (
                        <Accordion defaultActiveKey="0">
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>
                              Datos de Cuenta Bancaria
                            </Accordion.Header>
                            <Accordion.Body>
                              <Table>
                                <div className="mb-3 mt-4">
                                  <h5 className="fw-bold text-uppercase mb-2">
                                    Cuenta bancaria
                                  </h5>
                                </div>
                                <tbody>
                                  <tr>
                                    <td>
                                      <strong>Banco:</strong>
                                    </td>
                                    <td>BBVA</td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <strong>Nombre:</strong>
                                    </td>
                                    <td>NORMA SAUCEDO RUEDA</td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <strong>Cuenta:</strong>
                                    </td>
                                    <td>152 648 1623</td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <strong>CLABE:</strong>
                                    </td>
                                    <td>012 180 01526481623 4</td>
                                  </tr>
                                </tbody>
                              </Table>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      )}
                      {values.city != "Suchil" && (
                        <Form.Group
                          className="mb-3"
                          controlId="formBasicPassword"
                        >
                          <Form.Label>Comprobante de pago</Form.Label>
                          <Form.Control
                            type="file"
                            placeholder="Introduce tu comprobante"
                            name="voucher"
                            onChange={(e) => {
                              setFieldValue("voucher", e.target.files[0]);
                            }}
                            isInvalid={!!errors.voucher}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.voucher}
                          </Form.Control.Feedback>
                        </Form.Group>
                      )}
                      <GoogleReCaptcha
                        onVerify={onVerify}
                        refreshReCaptcha={refreshReCaptcha}
                      />
                      <div className="d-grid">
                        <Button
                          variant="primary"
                          type="submit"
                          disabled={!(dirty && isValid && token)}
                        >
                          Registrate
                        </Button>
                      </div>
                    </Form>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
    </Formik>
  );
}
