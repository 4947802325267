import React, { useState } from "react";
import RegisterForm from "../components/registerForm";
import PlayerData from "../components/playerData";

export default function Home() {
  const [playerData, setPlayerData] = useState();
  return (
    <div>
      {playerData ? (
        <PlayerData setPlayerData={setPlayerData} data={playerData} />
      ) : (
        <RegisterForm setPlayerData={setPlayerData} />
      )}
    </div>
  );
}
