import { useEffect, useState } from "react";
import { Row, Container, Table, Button } from "react-bootstrap";
import { getAllPlayers, validatePlayer } from "./../resources/db";
import Modal from "react-bootstrap/Modal";
import ToggleButton from "react-bootstrap/ToggleButton";
import BootstrapSwitchButton from "bootstrap-switch-button-react";

export default function Admin() {
  const [players, setPlayers] = useState([]);
  const [show, setShow] = useState();
  const [activeImage, setActiveImage] = useState();
  const [checked, setChecked] = useState();
  useEffect(() => {
    getAllPlayers().then(setPlayers);
    console.log("PLAYERS", players);
  }, []);
  function getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }
  return (
    <Container>
      <Row className="justify-content-center">
        <h2>Jugadores registrados:</h2>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Nombre</th>
              <th>Telefono</th>
              <th>Ciudad</th>
              <th>Fecha de nacimiento</th>
              <th>Edad</th>
              <th>CURP</th>
              <th>Comprobante de pago</th>
              <th>Validado</th>
            </tr>
          </thead>
          <tbody>
            {players.map((player, i) => (
              <tr key={player.id}>
                <td>{i + 1}</td>
                <td>
                  {player.name +
                    " " +
                    player.paternalName +
                    " " +
                    player.maternalName}
                </td>
                <td>{player.cellphone}</td>
                <td>{player.city}</td>
                <td>{player.birthDate}</td>
                <td>{getAge(player.birthDate)}</td>
                <td>
                  <Button
                    variant="primary"
                    type="button"
                    onClick={() => {
                      setActiveImage(player.curp);
                      setShow(true);
                    }}
                  >
                    Ver
                  </Button>
                </td>
                <td>
                  {player.voucher && player.voucher != "urlVoucher" && (
                    <>
                      <Button
                        variant="primary"
                        type="button"
                        onClick={() => {
                          setActiveImage(player.voucher);
                          setShow(true);
                        }}
                      >
                        Ver
                      </Button>
                    </>
                  )}
                </td>
                <td>
                  <BootstrapSwitchButton
                    checked={player.validated}
                    onlabel="Si"
                    offlabel="No"
                    onChange={(checked) => {
                      validatePlayer(player.id, checked);
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Row>
      <Modal
        fullscreen={true}
        show={show}
        onHide={function () {
          setActiveImage(null);
          setShow(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={activeImage} />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={function () {
              setActiveImage(null);
              setShow(false);
            }}
          >
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}
