import React, { useState } from "react";
import { Col, Button, Row, Container, Card, Form } from "react-bootstrap";
import * as formik from "formik";
import * as yup from "yup";
import { signin } from "../../resources/auth";
import { useNavigate } from "react-router-dom";

export default function Login() {
  const navigate = useNavigate();
  const { Formik } = formik;
  const [loginError, setLoginError] = useState(null);

  const schema = yup.object().shape({
    email: yup.string().required("El correo es requerido"),
    password: yup.string().required("La contraseña es requerida"),
  });

  return (
    <Formik
      validationSchema={schema}
      onSubmit={async (values) => {
        try {
          await signin(values.email, values.password);
          navigate("/admin");
        } catch (e) {
          if (e.message == "Firebase: Error (auth/invalid-credential).") {
            setLoginError("Los datos son incorrectos");
          } else {
            setLoginError(e.message);
          }
          console.log(e);
        }
      }}
      initialValues={{
        email: "",
        password: "",
        password2: "",
      }}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        touched,
        errors,
        dirty,
        isValid,
      }) => (
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xs={12}>
              <div className="border-3 border-primary border"></div>
              <Card className="shadow">
                <Card.Body>
                  <div className="mb-3 mt-4">
                    <h2 className="fw-bold text-uppercase mb-2">
                      Iniciar sesion
                    </h2>
                    <p className="mb-5">
                      Inicia sesion con tu correo electronico y password!
                    </p>
                    {loginError && <p>{loginError}</p>}
                    <Form className="mb-3" noValidate onSubmit={handleSubmit}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="text-center">
                          Correo electronico
                        </Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Introduce tu correo electronico"
                          name="email"
                          value={values?.email}
                          onChange={handleChange}
                          isInvalid={!!errors.email}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.email}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group
                        className="mb-3"
                        controlId="formBasicPassword"
                      >
                        <Form.Label>Contraseña</Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Introduce tu contraseña"
                          name="password"
                          value={values?.password}
                          onChange={handleChange}
                          isInvalid={!!errors.password}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.password}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <div className="d-grid">
                        <Button
                          variant="primary"
                          type="submit"
                          disabled={!(dirty && isValid)}
                        >
                          Inicia sesion
                        </Button>
                      </div>
                    </Form>
                    <div className="mt-3">
                      <p className="mb-0 text-center">
                        No tienes una cuenta{" "}
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            navigate("/signup");
                          }}
                          className="text-primary fw-bold"
                        >
                          Registrate
                        </a>
                      </p>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
    </Formik>
  );
}
