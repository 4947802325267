import { auth } from "../firebase";
import {
  createUserWithEmailAndPassword,
  signInAnonymously,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";

export const signup = (email, password) =>
  createUserWithEmailAndPassword(auth, email, password);

export const signin = (email, password) => {
  console.log("LOGIN ", email);
  return signInWithEmailAndPassword(auth, email, password);
};
export const signinAnonymously = () => signInAnonymously(auth);

export const sigout = () => signOut(auth);

export const getCurrentUser = () => {
  console.log("AUTH", auth);
  return auth.currentUser;
};
