import React from "react";
import { Col, Button, Row, Container, Card, Form } from "react-bootstrap";
import * as formik from "formik";
import * as yup from "yup";
import { signup } from "../../resources/auth";
import YupPassword from "yup-password";
import { useNavigate } from "react-router-dom";
YupPassword(yup);

export default function Signup() {
  const navigate = useNavigate();
  const { Formik } = formik;

  const schema = yup.object().shape({
    email: yup
      .string()
      .email("Introduce un correo valido")
      .required("El correo es requerido"),
    password: yup
      .string()
      .min(
        8,
        "Contraseña debe ser de al menos 8 caracteres y contener una mayuscula, una minuscula, un numero y un caracter especial"
      )
      .minLowercase(1, "Contraseña debe contener al menos una minuscula")
      .minUppercase(1, "Contraseña debe contener al menos una minuscula")
      .minNumbers(1, "Contraseña debe contener al menos un numero")
      .minSymbols(1, "Contraseña debe contener al menos un caracter especial")
      .required("La contraseña es requerida"),
    password2: yup
      .string()
      .oneOf([yup.ref("password"), null], "Las contraseñas deben ser iguales")
      .required("La contraseña es requerida"),
  });
  return (
    <Formik
      validationSchema={schema}
      onSubmit={(values) => {
        try {
          signup(values.email, values.password);
        } catch (e) {
          console.log(e);
        }
      }}
      initialValues={{
        email: "",
        password: "",
        password2: "",
      }}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        touched,
        errors,
        dirty,
        isValid,
      }) => (
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xs={12}>
              <div className="border-3 border-primary border"></div>
              <Card className="shadow">
                <Card.Body>
                  <div className="mb-3 mt-4">
                    <h2 className="fw-bold text-uppercase mb-2">
                      Crear cuenta
                    </h2>
                    <p className="mb-5">
                      Crea una cuenta con tu correo electronico!
                    </p>
                    <Form className="mb-3" noValidate onSubmit={handleSubmit}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="text-center">
                          Correo electronico
                        </Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Introduce tu correo electronico"
                          name="email"
                          value={values?.email}
                          onChange={handleChange}
                          isInvalid={!!errors.email}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.email}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group
                        className="mb-3"
                        controlId="formBasicPassword"
                      >
                        <Form.Label>Contraseña</Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Introduce tu contraseña"
                          name="password"
                          value={values?.password}
                          onChange={handleChange}
                          isInvalid={!!errors.password}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.password}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicPassword"
                      >
                        <Form.Label>Confirmar contraseña</Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Introduce tu contraseña"
                          name="password2"
                          value={values?.password2}
                          onChange={handleChange}
                          isInvalid={!!errors.password2}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.password2}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <div className="d-grid">
                        <Button
                          variant="primary"
                          type="submit"
                          disabled={!(dirty && isValid)}
                        >
                          Registrate
                        </Button>
                      </div>
                    </Form>
                    <div className="mt-3">
                      <p className="mb-0 text-center">
                        Ya tienes una cuenta{" "}
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            navigate("/login");
                          }}
                          className="text-primary fw-bold"
                        >
                          Inicia sesion
                        </a>
                      </p>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
    </Formik>
  );
}
