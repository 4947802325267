import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyC8FDVZ0reAq1SDYCNNYhxE0UTd731Gn5Q",

  authDomain: "torneo-de-ajedrez-3cb9b.firebaseapp.com",

  projectId: "torneo-de-ajedrez-3cb9b",

  storageBucket: "torneo-de-ajedrez-3cb9b.firebasestorage.app",

  messagingSenderId: "239645132058",

  appId: "1:239645132058:web:f62b2b4fb32888644f58a3",
};

const app = initializeApp(firebaseConfig);

const auth = getAuth();

const db = getFirestore();

const storage = getStorage();
export { auth, db, storage };
