import { db } from "../firebase";
import { collection, addDoc, setDoc, getDocs, doc } from "firebase/firestore";

export const addPlayer = (data) => {
  return addDoc(collection(db, "players"), data);
};

export const validatePlayer = (id, validated) => {
  const playerRef = doc(db, "players", id);
  return setDoc(playerRef, { validated: validated }, { merge: true });
};

export async function getAllPlayers() {
  const querySnapshot = await getDocs(collection(db, "players"));
  return querySnapshot.docs.map((doc) => {
    return { id: doc.id, ...doc.data() };
  });
}
