import React, { useEffect, useState } from "react";
import { Col, Button, Row, Container, Card, Form } from "react-bootstrap";
import { getCurrentUser } from "../resources/auth";
import Table from "react-bootstrap/Table";

export default function PlayerData({ data, setPlayerData }) {
  console.log("DATA", data);
  const [user, setUser] = useState();
  useEffect(() => {
    const user = getCurrentUser();
    setUser(user);
  }, []);
  const [error, setError] = useState(null);
  return (
    <Container>
      <Row className="justify-content-center">
        <Col md={8} lg={6} xs={12}>
          <div className="border-3 border-primary border"></div>
          <Card className="shadow">
            <Card.Body>
              <div className="mb-3 mt-4">
                <h2 className="fw-bold text-uppercase mb-2">
                  Datos registrados
                </h2>
                <p className="mb-5">
                  Los datos que actualmente estan registrados son:
                </p>
              </div>
              <Table>
                <tbody>
                  <tr>
                    <td>
                      <strong>Nombre:</strong>
                    </td>
                    <td>{data.name}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Primer apellido:</strong>
                    </td>
                    <td>{data.paternalName}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Segundo apellido:</strong>
                    </td>
                    <td>{data.maternalName}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Fecha de nacimiento:</strong>
                    </td>
                    <td>{data.birthDate}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Club:</strong>
                    </td>
                    <td>{data.club}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Ciudad de procedencia:</strong>
                    </td>
                    <td>{data.city}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Telefono:</strong>
                    </td>
                    <td>{data.cellphone}</td>
                  </tr>
                </tbody>
              </Table>
              <div className="d-grid">
                <Button variant="primary" type="button" onClick={()=>setPlayerData(null)}>
                  Registrar otro
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
